<template>
	<div>
		<headerTop></headerTop>
			<router-view />
		<tail></tail>
	</div>
	
</template>

<script>
	
	import headerTop from '@/components/HeaderTop.vue';
	import tail from '@/components/tail.vue';
	
	export default {
		components: {
			headerTop,
			tail
		}
	}
</script>

<style>
	
</style>
