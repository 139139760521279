<template>
	<div class="pagbox">
		<div class="Topbanner7">
			<div class="Topbanner7-body">
				<div class="Topbanner7-body-l">
					<div style="display: flex;">
						<img class="Top7-img" src="../assets/img/logo.png">
					</div>
					<div class="flex">
						<!-- 力瀚实业 -->
						<div style="margin-right: 0.10rem;">
							<img class="Top7-img2" src="../assets/img/syewm.jpg">
							<div
								style="font-size: 0.15rem;font-family: Source Han Sans CN;font-weight: 400;color: #fff;margin-top: 0.05rem;">
								力瀚实业公众号</div>
						</div>
						<!-- <div style="margin-right: 0.10rem;">
							<img class="Top7-img2" src="../assets/img/ewm.png">
							<div style="font-size: 0.15rem;font-family: Source Han Sans CN;font-weight: 400;color: #fff;margin-top: 0.05rem;">力瀚网络公众号</div>
						</div> -->
						<div>
							<img class="Top7-img2" src="../assets/img/qywx.png">
							<div
								style="font-size: 0.15rem;font-family: Source Han Sans CN;font-weight: 400;color: #fff;margin-top: 0.05rem;">
								企业微信二维码</div>
						</div>

					</div>
				</div>
				<div class="Topbanner7-body-r">
					<div class="Top7-r-tit">
						<div class="Top7-r-tit-cell">
							<div style="margin-bottom: 20px;"><span class="tit-cell-a">热门产品</span></div>
							<router-link to="/middleground">
								<div><span class="tit-cell-a">业务中台</span></div>
							</router-link>
							<router-link to="/dataMiddleground">
								<div><span class="tit-cell-a">数据中台</span></div>
							</router-link>
							<router-link to="/">
								<div><span class="tit-cell-a">开放中台</span></div>
							</router-link>
							<router-link to="/supplyChain">
								<div><span class="tit-cell-a">供应链系统</span></div>
							</router-link>
							<router-link to="/applet">
								<div><span class="tit-cell-a">小程序商城</span></div>
							</router-link>
							<router-link to="/microMall">
								<div><span class="tit-cell-a">微商城</span></div>
							</router-link>
							<router-link to="/PCShoppingMall">
								<div><span class="tit-cell-a">PC商城</span></div>
							</router-link>

						</div>
						<div class="Top7-r-tit-cell">
							<div style="margin-bottom: 20px;"><span class="tit-cell-a">行业方案</span></div>
							<router-link to="/newRetail">
								<div><span class="tit-cell-a">新零售</span></div>
							</router-link>
							<router-link to="/onlineRetailers">
								<div><span class="tit-cell-a">电商</span></div>
							</router-link>
							<router-link to="/supplyChain">
								<div><span class="tit-cell-a">供应链</span></div>
							</router-link>
							<router-link to="/welfare">
								<div><span class="tit-cell-a">企业福利</span></div>
							</router-link>

						</div>
						<div class="Top7-r-tit-cell">
							<div style="margin-bottom: 20px;"><span class="tit-cell-a">关于我们</span></div>
							<router-link to="/about">
								<div><span class="tit-cell-a">公司简介</span></div>
							</router-link>
							<!-- <router-link to="/about" >
								<div><span class="tit-cell-a">发展历程</span></div>
							</router-link> -->
							<router-link to="/about">
								<div><span class="tit-cell-a">联系我们</span></div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- 力瀚实业 -->
			<div class="Topbanner7-Bot">力瀚实业（上海）有限公司 版权所有 <a @click="toBeian" class="Bot-span">沪ICP备19043238号</a></div>
			<div style="margin:0 auto; font-size: 16px;">
				<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702007474"
					style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
					<!-- < img src="" style="float:left;" /> -->
					<img src="../../src/assets/img/jh.png" style="float:left;" alt="">
					<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备
						31010702007474号</p>
				</a>
			</div>
			<!-- <div class="Topbanner7-Bot">力瀚网络科技（上海）有限公司 版权所有 <a href="https://beian.miit.gov.cn/" class="Bot-span">沪ICP备2021020882号-1</a></div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tail',
		data() {
			return {

			}
		},
		created() {

			// console.log('页面大小',document.documentElement.clientWidth)
		},
		methods: {
			toBeian() {
				window.open('https://beian.miit.gov.cn/');
			},
		}
	}
</script>

<style>
	@media screen and (max-width:750px) {
		.Topbanner7-body {
			width: 90% !important;
			padding: 0 !important;
		}
	}

	.pagbox {
		width: 100%;
		/* position: fixed;
		bottom: 0; */
		z-index: 103;
		position: relative;
		background-color: #060628;
	}

	.Topbanner7 {
		width: 100%;
		/* height: 300px; */
		background-color: #060628;
		z-index: 2;
		position: relative;
	}

	.Topbanner7-body {
		/* width: 1300px; */
		width: 70%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0.60rem;
	}

	.Topbanner7-body-l {
		/* flex: 1; */
		/* text-align: right; */
		/* z-index: 2; */
		/* height: 280px; */
		margin-right: 1rem;
		/* margin-top: 20px; */
	}

	.Top7-img {
		width: 1.00rem;
		margin-bottom: 0.10rem;
	}

	.Top7-img2 {
		width: 1.20rem;
		/* margin-right: 10px; */
	}

	.Topbanner7-body-r {
		width: 5.00rem;
	}

	.Top7-r-tit {
		width: 100%;
		display: flex;
		color: #ccc;
		font-size: 0.14rem;
		/* height: 100%; */
	}

	.Top7-r-tit-cell {
		flex: 1;
		padding: 0.20rem 0;
		line-height: 0.25rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.tit-cell-a:hover {
		color: #188CF3 !important;
	}

	.Topbanner7-Bot {
		width: 100%;
		border-top: 1px solid;
		padding: 0.20rem 0;
		text-align: center;
		color: #2d3d5e;
		font-size: 0.14rem;
	}

	.Bot-span {
		color: #2d3d5e;
	}

	.Bot-span:hover {
		color: #fff;
		cursor: pointer;

	}
</style>
