<template>
	<div class="pagebox">
		<div class="Topbanner">
			<div class="Topbanner-body">
				<div class="Topbanner-b-top">
					<div class="b-top-imgbox">
						<router-link to="/">
							<img class="b-top-img" src="../assets/img/logo.png">
						</router-link>

					</div>
					<div class="b-top-cell">
						<router-link to="/" class="t-cell-c">
							<div class="t-cell-c-span">
								首页
							</div>
						</router-link>
						<div class="t-cell-c product">
							<div class="t-cell-c-span">
								产品
								<i class="el-icon-arrow-down"></i>
							</div>
							<div class="product-box">
								<div class="product-box-cent flex">
									<div class="product-box-cent-cell">
										<div class="product-box-cent-cell-top flex">
											<img style="width: 0.24rem;" src="../assets/img/product-icon1.png">
											<span style="margin-left: 0.20rem;">中台</span>
										</div>
										<router-link to="/middleground">
											<div class="product-box-cent-cell-cent">
												<span class="product-box-cent-cell-cent-s">业务中台</span><br />
												<span class="product-box-cent-cell-cent-b">全渠道业务，资源一体化融合</span>
											</div>
										</router-link>
										<router-link to="/dataMiddleground">
											<div class="product-box-cent-cell-cent">
												<span class="product-box-cent-cell-cent-s">数据中台</span><br />
												<span class="product-box-cent-cell-cent-b">企业的数据智能</span>
											</div>
										</router-link> 

										<!-- <router-link to="">
											<div class="product-box-cent-cell-cent">
												<span class="product-box-cent-cell-cent-s">开放平台</span><br />
												<span class="product-box-cent-cell-cent-b">满足企业不同场景的数据需求</span>
											</div>
										</router-link> -->

									</div>
									<div class="product-box-cent-cell">
										<div class="product-box-cent-cell-top flex">
											<img style="width: 0.24rem;" src="../assets/img/product-icon2.png">
											<span style="margin-left: 0.20rem;">商城系统</span>
										</div>
										<router-link to="/supplyChain">
											<div class="product-box-cent-cell-cent">
												<span class="product-box-cent-cell-cent-s">供应链系统</span><br />
												<span class="product-box-cent-cell-cent-b">S2B2C供应链平台，满足商家批发采购需求</span>
											</div>
										</router-link>

									</div>
									<div class="product-box-cent-cell">
										<div class="product-box-cent-cell-top flex">
											<img style="width: 0.24rem;" src="../assets/img/product-icon3.png">
											<span style="margin-left: 0.20rem;">应用中端</span>
										</div>
										<router-link to="/applet">
											<div class="product-box-cent-cell-cent" style="margin-bottom: 30px;">
												<span class="product-box-cent-cell-cent-s">小程序商城</span>

											</div>
										</router-link>
										<router-link to="/microMall">
											<div class="product-box-cent-cell-cent" style="margin-bottom: 30px;">
												<span class="product-box-cent-cell-cent-s">微商城</span>

											</div>
										</router-link>
										<router-link to="/PCShoppingMall">
											<div class="product-box-cent-cell-cent" style="margin-bottom: 30px;">
												<span class="product-box-cent-cell-cent-s">PC商城</span>

											</div>
										</router-link>
									</div>
								</div>
							</div>
							<!-- <img class="transimg" style="width: 14px;margin-left: 5px;" src="img/bottom.png" > -->
						</div>
						<div class="t-cell-c hyfa">
							<div class="t-cell-c-span">
								行业方案
								<i class="el-icon-arrow-down"></i>
							</div>
							<!-- <img class="transimg" style="width: 14px;margin-left: 5px;" src="img/bottom.png" > -->
							<div class="hyfa-box flex">
								<router-link to="/newRetail">
									<div class="hyfa-box-cell">新零售</div>
								</router-link>
								<router-link to="/onlineRetailers">
									<div class="hyfa-box-cell">电商</div>
								</router-link>
								<router-link to="/supplyChain">
									<div class="hyfa-box-cell">供应链</div>
								</router-link>
								<router-link to="/welfare">
									<div class="hyfa-box-cell">企业福利</div>
								</router-link>
							</div>
						</div>
						<router-link to="/case" class="t-cell-c">
							<div class="t-cell-c-span">
								案例<span style="font-size: 0.2rem;">.</span>分享
							</div>
						</router-link>
						<div class="t-cell-c hyfa">
							<div class="t-cell-c-span">
								渠道招募
								<i class="el-icon-arrow-down"></i>
							</div>
							<!-- <img class="transimg" style="width: 14px;margin-left: 5px;" src="img/bottom.png" > -->
							<div class="hyfa-box flex">
								<a href="https://console.zt.lihansy.com.cn/supplychain/home?type=255&tenantId=255">
									<div class="hyfa-box-cell">招募供应商</div>
								</a>
								<!-- <a href="http://zt-admin.lihansy.com.cn:88/supplychain/home?type=1&checkedSatuts=">
									<div class="hyfa-box-cell">招募合伙人</div>
								</a> -->
								<!-- <router-link to="/newRetail">
									<div class="hyfa-box-cell">招募供应商</div>
								</router-link> -->
								<!-- <router-link to="/onlineRetailers">
									<div class="hyfa-box-cell">招募合伙人</div>
								</router-link> -->
							</div>
						</div>
						<!-- <router-link to="/Journalism" class="t-cell-c">
							<div class="t-cell-c-span">
								建设分享
							</div>
						</router-link> -->
						<router-link to="/about" class="t-cell-c">
							<div class="t-cell-c-span">
								关于我们
							</div>
						</router-link>

						<div class="t-cell-but">
							<!-- <div style="flex: 1;font-size: 0.15rem;">演示地址</div>
							<img class="but-r" src="../assets/img/r.png"> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'headerTop',
		data() {
			return {

			}
		},
		created() {

			// console.log('页面大小',document.documentElement.clientWidth)
		}
	}
</script>

<style lang="scss">
	@media screen and (max-width: 750px) {
		.Topbanner-body{
			width: 95% !important;
			// display: none !important;
		}
		
		.t-cell-but{
			width: 1rem !important;
		}
		
		.t-cell-c{
			margin-right: 0.2rem !important;
		}
		
		.product-box-cent{
			width: 80% !important;
		}
	}
	.pagebox {
		width: 100%;
		background-color: #060628;
		position: relative;
		z-index: 102;
	}

	.Topbanner {
		width: 100%;
		position: relative;
		/* z-index: 1001; */
	}

	.Topbanner-body {
		width: 80%;
		margin: 0 auto;
		padding-top: 0.20rem;
		display: flex;
		justify-content: space-between;
		/* height: ; */
	}
	
	.b-top-imgbox .router-link-active{
		font-size: 0.40rem;
	}

	.b-top-imgbox {
		// width: 50%;
		// padding-right: 0.30rem;
		display: flex;
		// padding-bottom: 0.20rem;
	}

	.Topbanner-b-top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.b-top-img {
		width: 0.8rem;
	}

	.b-top-cell {
		// width: 40%;
		display: flex;
		color: #fff;
		align-items: center;
		font-size: 0.14rem;
	}

	.t-cell-c {
		// width: 84px;
		/* height: 60px; */
		text-align: center;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.30rem;
		/* position: relative; */
	}

	.t-cell-c-span {
		position: relative;
		padding-bottom: 0.20rem;
		width: 100%;
		height: 100%;
	}

	.t-cell-c-span:before {
		content: "";
		position: absolute;
		top: 0;
		left: 100%;
		/*下划线从右侧开始显示*/
		width: 0;
		height: 100%;
		border-bottom: 2px solid #239ef7;
		transition: 0.2s all linear;
		/*动画效果*/
	}

	.hyfa {
		/* position: relative; */
	}

	.hyfa:hover .hyfa-box {
		/* z-index: 102 !important; */
		/* visibility: initial; */
		opacity: 1;
		visibility: visible;
		z-index: 102 !important;
		/* animation-name: example; */
		/* animation-duration: .2s; */
		/* animation-delay: 2s; */
	}

	.el-icon-arrow-down {
		/* display: none; */
		transition: 0.2s all linear;
		-webkit-transition: 0.2s all linear;
	}

	.hyfa:hover .el-icon-arrow-down {
		transform: rotate(180deg);
	}

	@keyframes example {
		0% {
			opacity: 0;
		}

		50% {
			opacity: .5;
		}

		100% {
			opacity: 1;
		}
	}

	.hyfa-box {
		position: absolute;
		top: 0.80rem;
		z-index: 100;
		flex-wrap: wrap;
		width: 100%;
		left: 0;
		/* height: 100px; */
		background-color: #060628;
		/* justify-content: start; */
		opacity: 0;
		visibility: hidden;
		/* display: none; */
		transition: 0.2s all linear;
		-webkit-transition: 0.2s all linear;
		/* align-items: inherit; */
	}

	.product:hover .product-box {

		opacity: 1;
		visibility: visible;
		z-index: 102 !important;
		/* animation-name: example;
		animation-duration: .2s; */

	}

	.product:hover .el-icon-arrow-down {
		transform: rotate(180deg);
	}

	.product-box {
		position: absolute;
		top: 0.80rem;
		z-index: 100;
		flex-wrap: wrap;
		width: 100%;
		/* height: 200px; */
		padding: 0.30rem 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		/* display: none; */
		transition: 0.2s all linear;
		-webkit-transition: 0.2s all linear;
		background-color: #060628;
	}

	.product-box-cent {
		width: 60%;
		height: 100%;
		margin: 0 auto;
		text-align: left;
		align-items: initial;

	}

	.product-box-cent-cell {
		flex: 1;

	}

	.product-box-cent-cell-top {
		font-size: 0.18rem;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		/* margin-top: 20px; */
		justify-content: initial;
		margin-bottom: 0.30rem;
	}

	.product-box-cent-cell-cent {

		line-height: 0.20rem;
		margin-bottom: 0.10rem;

	}

	.product-box-cent-cell-cent:hover .product-box-cent-cell-cent-s {
		color: #239ef7;
	}

	.product-box-cent-cell-cent:hover .product-box-cent-cell-cent-b {
		color: #239ef7;
	}

	.product-box-cent-cell-cent-s {
		font-size: 0.15rem;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		transition: 0.2s all linear;
		cursor: pointer;
	}

	.product-box-cent-cell-cent-b {
		font-size: 0.12rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #ccc;
		transition: 0.2s all linear;
		cursor: pointer;
	}

	.hyfa-box-cell {
		padding: 0.20rem;
		color: #fff;
		font-size: 0.20rem;
		transition: 0.2s all linear;
	}

	.hyfa-box-cell:hover {
		color: #239ef7;
	}

	.t-cell-c-span:hover:before {
		left: 0;
		/*鼠标滑过时，下划线从右向左移动*/
		width: 100%;
		/*同时，下划线宽度从0-100%*/
	}

	.t-cell-c:hover~.t-cell-c:before {
		/*~ 选择器：查找指定元素后面的所有兄弟结点*/
		left: 0;
		/*处于hover后面元素的下划线从左侧开始显示*/
	}
	
	/* 渐变动画 */
	// @keyframes animate{
	//     from{
	//         // transform: rotate(0deg);
	//         filter: drop-shadow(0 0 0 -10px #188cf3);
	//     }to{
	//         // transform: rotate(360deg);
	//         filter: drop-shadow(0 0 10px #188cf3);
	//     }
	// }
	
	@-webkit-keyframes an_0 {
		0% {
			box-shadow: 0 0 0 0 rgba(24, 220, 242, .7)
		}
	
		50% {
			box-shadow: 0 0 10px 3px rgba(24, 220, 242, .7)
		}
	
		to {
			box-shadow: 0 0 0 0 rgba(24, 220, 242, .7)
		}
	}
	
	@keyframes an_0 {
		0% {
			box-shadow: 0 0 0 0 rgba(24, 220, 242, .7)
		}
	
		50% {
			box-shadow: 0 0 10px 3px rgba(24, 220, 242, .7)
		}
	
		to {
			box-shadow: 0 0 0 0 rgba(24, 220, 242, .7)
		}
	}

	.t-cell-but {
		width: 1.40rem;
		height: 0.45rem;
		
		// background-color: #188cf3;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// border-radius: 0.20rem;
		// text-align: center;
		margin-bottom: 0.20rem;
		// -webkit-animation: an_0 1.2s linear infinite;
		// animation: an_0 1.2s linear infinite;
	}

	.but-r {
		width: 0.20rem;
		height: 0.20rem;
		margin-right: 0.10rem;
	}

	img {
		/* position: absolute; */
		// width: 100%;
		vertical-align: top;
	}
</style>
